<template>
  <div class="main_container">
    <div class="">
      <div class="head_bg">
        <van-image
          width="100vw"
          src="https://activity-resource.321mh.com/nft/xymh01/bindbox/xymh1_1.png"
        />
        <div class="has_code">已产生 XXX 抽签码</div>
      </div>
    </div>
    <div class="meng"></div>
    <div class="content">
      <div class="rules_wraper">
        <div class="rules_box">
          <div class="result">
            <div class="result_left"><img :src="happy" alt="" />恭喜您中签</div>
            <div class="">中签结果<van-icon name="arrow" /></div>
          </div>
        </div>
      </div>
      <div class="rules_wraper">
        <div class="rules_box">
          <div class="title">
            我的抽签码 <span>{{ myCode.length }}</span
            >份
          </div>
          <div class="code">
            <div
              class="code_item"
              v-for="(item, index) in myCode"
              :key="index"
              v-show="index < 6 || showMall"
            >
              103284
            </div>
            <div
              class="code_add"
              v-for="(item, index) in 3 - (myCode.length % 3)"
              :key="index"
            ></div>
          </div>
          <div
            class=""
            style="text-align: center"
            v-if="myCode.length > 6"
            @click="showMall = !showMall"
          >
            {{ showMall ? '收起' : '查看全部' }}
            <van-icon :name="showMall ? 'arrow-up' : 'arrow-down'" />
          </div>
        </div>
      </div>
      <div class="rules_wraper">
        <div class="rules_box">
          <div class="rule_title">
            <img src="@/imgs/icon/icon_mkbt_left@2x.png" alt="" />
            <span>活动规则</span>
            <img src="@/imgs/icon/icon_mkbt_right@2x.png" alt="" />
          </div>
          <div class="">
            1、2022年11月8日16:00至2022年11月11日12:00活动期间内，转发微信公众号【看漫艺术平台】「上古兽蛋」发售活动文章到150人以上微信数藏群或500人以上QQ数藏群（非看漫官方社群）；<br /><br />
            2、附上文案“看漫数藏携手十八数藏推出“上古神兽”系列藏品，参与中十八数藏——精卫等众多豪礼”停留三分钟后截图并保存；<br /><br />
            3、每分享至一个符合规则的数藏社群，审核通过后，可获得1张抽签码，抽签码获取不设上限，抽签码越多中奖概率越大；<br /><br />
            4、本次抽签将通过摇号的方式随机抽取100名中奖用户，于11月11日14:00陆续空投藏品「上古兽蛋」。<br /><br />
          </div>
        </div>
      </div>
      <div class="btn_box">
        <div class="bt_btn">上传截图</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { nftUtils } from '@/utils';
export default {
  setup() {
    const state = reactive({
      isApp: nftUtils.isInApp(),
      myCode: [1, 1, 1, 1],
      showMall: false
    });

    return {
      ...toRefs(state),
      happy: require('../../imgs/icon/icon_happy_blue_24@2x.png'),
      cry: require('../../imgs/icon/icon_cry_blue_24@2x.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.appTop {
  padding-top: 70px !important;
}
.main_container {
  position: relative;
}
.has_code {
  position: absolute;
  top: 255px;
  background-color: $primary;
  font-size: 12px;
  color: #000000;
  padding: 3px 12px;
  border-radius: 0px 10px 10px 0;
  z-index: 99;
}

.meng {
  width: 100vw;
  height: 160px;
  position: absolute;
  top: 259px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
}
.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .result_left {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 16px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.content {
  padding: 0 16px 120px;
  box-sizing: border-box;
  position: absolute;
  top: 305px;
  width: 100vw;
  .rules_wraper {
    padding: 4px;
    margin-bottom: 28px;
    background: #2f293f;

    font-size: 12px;
    .rules_box {
      border: 1px solid rgba(88, 222, 221, 0.6);
      padding: 12px;
      .title {
        font-size: 16px;
        margin-bottom: 10px;
        span {
          color: $primary;
        }
      }
      .code {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .code_item {
          width: 95px;
          height: 44px;
          background-image: url('../../imgs/icon/icon_cqm_bg_xiao@2x.png');
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: $primary;
          font-weight: 600;
          margin-bottom: 12px;
          // margin-right: 13px;
        }
        .code_add {
          width: 95px;
        }
      }
      .rule_title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 14px;
        span {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          margin: 0 4px;
        }
        img {
          width: 77px;
          height: 10px;
        }
      }
    }
  }
}
.btn_box {
}
.bt_btn {
  width: 252px;
  height: 44px;
  background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #1c172a;
  position: fixed;
  bottom: 92px;
  left: 50%;
  transform: translate(-50%, 0%);
}
</style>
